/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 30px;
  margin-top: 30px;
  
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 34px;
  width: 34px;
}


/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  /*background: #373a47;*/
  background: rgba(0, 0, 0, 0.7);
  /*padding: 2.5em 1.5em 0;*/
  font-size: 2.15em;
  font-family: 'Architects Daughter', cursive;
  border-bottom-left-radius: 30px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  margin: auto;
  text-align: center;
  padding-top: 30%;
}

@media only screen and (max-height: 399px) {
  .bm-item-list {
    padding-top: 5%;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-align: center;
  color: #226f74;
  filter:brightness(1.3);
}

/* Styling of overlay (important) */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#socialBurgerDiv{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

