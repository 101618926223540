$color: #284cff;

.wrapper {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  height: 100vh;
}

.liquidButton {
  //background-color: red;
  border-radius: 999px;
  width: 100%;
  height: 100% !important;
  //border: 5px solid $color;
  //font-family: 'VT323', monospace;

  //text-shadow: 0 3px 5px rgba(#000, 0.25);
  //white-space: nowrap;
  //color: #fff;
  background: transparent;
  background-image: url("nav-bg-fill-blue.png");

  background-repeat: repeat-x;
  background-position: 0 -100%;
  transition: 1.5s ease;
  
  &:hover {
    background-position: 500% 100%;
  }
  
  @for $i from 1 through 18 {
    &:nth-child(#{$i}) {
      filter: hue-rotate(($i * 1) + deg);
    }
  }
}