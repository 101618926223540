  #fishCarousel2 img {
    display: block;
    width: 100%;
    border-radius: 30px;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  }
  
  section {

    transform: rotate(-2deg);
    width: 100%;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 30px;
    user-select: none;
  }
  
  article {
    display: flex;
    width: 200%;
    animation: bannermove 35s linear infinite;
  }
  
  article.paused {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  
  #fishCarousel2 ul {
    
    display: flex;
    background: transparent;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  
@media screen and (min-width: 2000px) {
  li {
    width: 100%;
  }
  article {
    animation: bannermove 55s linear infinite;
  }
}

@media screen and (min-width: 801px) and (max-width: 1999px)  {
  li {
    width: 250px;
  }
  article {
    animation: bannermove 65s linear infinite;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 800px) {
  li {
    width: 130px;
  }
  article {
    animation: bannermove 35s linear infinite;
  }
}
  li {
    background: transparent;
    padding: 7px;
  }
  
  li:nth-child(2) {
    background: transparent;
  }
  
  li:nth-child(3) {
    background: transparent;
  }
  
  @keyframes bannermove {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-50%);
    }
    
  }