.dirWrapper{

  display: grid;
  grid-template-columns: auto auto auto;
  /*background-color: #2196F3;*/
  text-align: left !important;
  grid-column-gap: 30px;
  width: 100%;
  margin: auto;
  /*border-bottom: 4px  solid rgba(0, 0, 0, 0.8);
  border-top: 4px  solid rgba(0, 0, 0, 0.8);
  border-left: 4px solid rgba(0, 0, 0, 0.8);
  border-right: 4px solid rgba(0, 0, 0, 0.8);*/
  
  
}

.fullPondDirWrapper .Collapsible__contentInner{
  user-select: none;
}

.fullPondDirWrapper{
  text-align: center !important;
  padding-bottom: 200px;
  padding-top: 30px;
  position: relative;
  z-index: 5;
}

.pondDirHeader{
  padding: 20px;
  user-select: none;
}


.gridItem{
  color:#dedede;
  user-select: none;
  width: fit-content;
}

a:hover {
  color: yellow;
}



@media only screen and (min-width: 1250px) and (max-width: 1700px) {
  .dirWrapper{
    width: 80%;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1250px) {
  .dirWrapper{
    width: 80%;
    grid-template-columns: auto auto;
  }
}

@media only screen and (min-width: 0px) and (max-width: 749px) {
  .dirWrapper{
    width: 100%;
    grid-template-columns: auto;
  }
}


.filterGrid input{
  text-align: center; 
  font-size: 1.4em;
  font-family: 'Architects Daughter';
}
.filterGrid.MuiInput-underline:after{
  border-bottom: 2px solid yellow;
}
.filterGrid::-webkit-input-placeholder{
  color: #dedede;

}