::selection{background-color: salmon; color: white;}
.parallax > use{
  animation:move-forever 12s linear infinite;
  &:nth-child(1){animation-delay:-2s;}
  &:nth-child(2){animation-delay:-2s; animation-duration:5s}
  &:nth-child(3){animation-delay:-4s; animation-duration:3s}
}

@keyframes move-forever{
  0%{transform: translate(-90px , 0%)}
  100%{transform: translate(85px , 0%)} 
}



.editorial {
  display: block;
  width: 100%;
  height: 10%;
 
  margin-bottom: -1px; /*on some small screens 1px differentiation*/
  bottom: 0;
  position: absolute;
}



/*prevent many large-by-comparison ripples by shrinking the height*/
@media (max-width:50em){
  .content h1{font-size: 12vmax}
  .editorial{height:7.5%;}
  .bodyFooterWrapper{top: 27.5%;}
  /*.header{height:calc(10% + 17vw)}*/
  /*.bodyFooterWrapper{top: 17vw;}*/

}

